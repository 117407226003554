import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const WEBINAR_BANNER_VIDEO = "https://www.youtube.com/embed/36VJm8k1zTg";

const WEBINAR_LEARNING_POINTS = [
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icnn1.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "Why do you have diabetes in the first place?",
  },
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icnn2.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "How can we fix the main problem causing your diabetes?",
  },
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icn3.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "Can we find a way for you to stop taking medicine or insulin?",
  },
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icnn4.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "How do we figure out the best diet for you?",
  },
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icnn5.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "Complications of medicines",
  },
  {
    icon: (
      <StaticImage
        src="../images/LandingPage/learn_icnn6.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    text: "Difference between type 1 and type 2 diabetes",
  },
];

const WEBINAR_DOCTORSDETAILS = [
  {
    img: (
      <StaticImage
        src="../images/LandingPage/rohit_3x.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    name: "Dr Rohit warrier",
    designation:
      "MBBS, MS (UTSW,US), DABRM, EBEEDM (EuropeanBoard, Endocrinology, Diabetes & Metabolism), MSc Diab.(SouthWales,UK), CC-Diabetology (Jaipur National University/RSSDI), SCOPE cert. Obesity specialist (UK).",
    experience: "15+ Years Of Experience",
    patients: "15000+ Patients treated",
  },
  {
    img: (
      <StaticImage
        src="../images/LandingPage/dorphy_3x.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    name: "Dr Dorphy",
    designation: "MBBS MD",
    experience: "20+ Years Of Experience",
    patients: "15000+ Patients Treated",
  },
  {
    img: (
      <StaticImage
        src="../images/LandingPage/bushra_3x.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    name: "Dr Bushra Pawane",
    designation:
      "MBBS, C.DIAB, PG DIP (USA), PG DIP (UK), ADA Certified in Treatment Strategies for Diabetes and Associated Complications",
    experience: "10+ Years Of Experience",
    patients: "10000+ Patients Treated",
  },
  {
    img: (
      <StaticImage
        src="../images/LandingPage/mamtha_3x.png"
        alt="timer_icon"
        className="img-fluid"
        placeholder="blurred"
        quality={90}
      />
    ),
    name: "Dr Mamtha Prakash",
    designation:
      "MBBS, Skilled with succinct medicine, diet and lifestyle specialist",
    experience: "6+ Years Of Experience",
    patients: "5000+ Patients Treated",
  },
];

const WEBINAR_REVERSEMANAGE_HEADER = [
  "Compare",
  "Twin Health Reversal",
  "Other Treatments",
];

const WEBINAR_REVERSEMANAGE = [
  {
    category: "Treatment Goal",
    twinHealth: "Diabetes Reversal",
    otherTreatments: "Diabetes Management",
  },
  {
    category: "Treatment Philosophy",
    twinHealth:
      "Personalized, Precise, Scientific, Tech-driven, Treats root cause",
    otherTreatments: "Generic, One-size-fits-all, Treats symptoms",
  },
  {
    category: "Treatment Method",
    twinHealth:
      "Comprehensive - nutrition, medicine, sleep, activity & lifestyle",
    otherTreatments: "Diabetes Management",
  },
  {
    category: "Side Effects",
    twinHealth: "Nil",
    otherTreatments: "Possible internal organ damage over a period of time",
  },
  {
    category: "Overall Mood & Energy",
    twinHealth: "Improves",
    otherTreatments: "Deteriorates",
  },
  {
    category: "Other Chronic Diseases",
    twinHealth:
      "Treats other chronic diseases like High BP, Cholesterol, Obesity",
    otherTreatments: "Doesn’t treat them",
  },
];

const WEBINAR_TESTIMONIAL_DETAILS = [
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_1.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Alpana Mahapatra, 61, IITian",
    subHeading: "From 7.2, My HbA1c came down to 5.7",
    description:
      "While joining Twin’s Diabetes Remission Program, From 7.2, My HbA1c came down to 5.7 within One Year. This has helped improve my health & my body’s nutrition levels. Thank you so much Twin Health!",
    diseaseCure: "HbA1c",
    programStart: "7.2",
    programStartText: "Start of the program",
    yearEndValue: "5.7",
    yearText: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_2.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Donald Sequeira, 63",
    subHeading: "From 3 tablets a day, now I'm down to 1",
    description:
      "I have had diabetes for the last 16 years. I joined Twin Health and the progress I've made is incredible. Today my average HbA1c is 6 and from 3 tablets a day, now I'm down to 1.",
    diseaseCure: "Medicines",
    programStart: "3",
    programStartText: "Start of the program",
    yearEndValue: "1",
    yearText: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_3.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Srinivasan, diagnosed with diabetes in 2018",
    subHeading: "I wanted to save myself from type-2 diabetes",
    description:
      "I wanted to save myself from type-2 diabetes and that's when I attended a Twin Webinar. Thanks to their Diabetes Remission Program, now my HbA1c level is less than 5.8 and I'm not on medications anymore.",
    diseaseCure: "Medicines",
    programStart: "2",
    programStartText: "Start of the program",
    yearEndValue: "0",
    yearText: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_4.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Divya, 34",
    subHeading: "My HbA1c levels dropped from 9.1 to 6.2",
    description:
      "I was in the Diabetes Remission Program for the past 1 year and my HbA1c levels dropped from 9.1 to 6.2 with a balanced & nutrition diet along with other everyday activities suggested by the team.",
    diseaseCure: "HbA1c",
    programStart: "9.1",
    programStartText: "Start of the program",
    yearEndValue: "6.2",
    yearText: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_5.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Smidha, 43",
    subHeading: "My HbA1c was 11 & now it's at ~7.",
    description:
      "I've been in the Twin Diabetes Remission Program for the last 1 year and it's been very easy to improve my health. When I joined, My HbA1c was 11 & now it's at ~7.",
    diseaseCure: "HbA1c",
    programStart: "11",
    programStartText: "Start of the program",
    yearEndValue: "7",
    yearText: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_6.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Usha, diagnosed with diabetes in 2015",
    subHeading: "Now I'm not taking any tablets",
    description:
      "One year ago my brother advised me to join the Twin Health Diabetes Remission Program. Now I'm not taking any tablets and my sugar level, every day, is around 120.",
    diseaseCure: "Sugar mg/dl",
    programStart: "350",
    programStartText: "Start of the program",
    yearEndValue: "120",
    yearText: "Within 1 Year",
    diseaseCure_2: "Medicines",
    programStart_2: "2",
    programStartText_2: "Start of the program",
    yearEndValue_2: "0",
    yearText_2: "Within 1 Year",
  },
  {
    userImage: (
      <StaticImage
        src="../images/LandingPage/testimonial_7.png"
        alt="testimonial"
        placeholder="blurred"
        loading="lazy"
      />
    ),
    heading: "Rangarajan, 63",
    subHeading: "I was free of all my diabetes medications.",
    description:
      "At the time of joining the Twin Diabetes Reversal Program, my sugar level was 280 mg/dl and HbA1c was 7.4%. Within 5 days my sugar levels came down to 70 mg and in 2 weeks, I was free of all my diabetes medications.",
    diseaseCure: "Sugar mg/dl",
    programStart: "280",
    programStartText: "Start of the program",
    yearEndValue: "70",
    yearText: "Within 1 Year",
    diseaseCure_2: "Medicines",
    programStart_2: "2",
    programStartText_2: "Start of the program",
    yearEndValue_2: "0",
    yearText_2: "Within 1 Year",
  },
];

const WEBINAR_TIMINGS = [
  { day: 1, startTime: "19:00", endTime: "20:00" }, // Monday
  { day: 3, startTime: "19:00", endTime: "20:00" }, // Wednesday
  { day: 5, startTime: "19:00", endTime: "20:00" }, // Friday
  { day: 6, startTime: "19:00", endTime: "20:00" }, // Saturday
  { day: 0, startTime: "11:00", endTime: "12:00" }, // Sunday
];

const constants = {
  WEBINAR_BANNER_VIDEO: WEBINAR_BANNER_VIDEO,
  WEBINAR_LEARNING_POINTS: WEBINAR_LEARNING_POINTS,
  WEBINAR_DOCTORSDETAILS: WEBINAR_DOCTORSDETAILS,
  WEBINAR_REVERSEMANAGE_HEADER: WEBINAR_REVERSEMANAGE_HEADER,
  WEBINAR_REVERSEMANAGE: WEBINAR_REVERSEMANAGE,
  WEBINAR_TESTIMONIAL_DETAILS: WEBINAR_TESTIMONIAL_DETAILS,
  WEBINAR_TIMINGS: WEBINAR_TIMINGS,
};

export default constants;

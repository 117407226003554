import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// CSS
import * as styles from "./Footer.module.css";

const FooterComponent = () => {
  return (
    <>
      <div className={`container-fluid ${styles.webinarLp__footer__cont}`}>
        <div className={`row ${styles.webinarLp__footer__row}`}>
          <div className={`col-12`}>
            {/* LOGO IMAGE */}
            <div className="text-center">
              <StaticImage
                src="../../../../images/LandingPage/twin-logo-icn.png"
                alt="banner_image"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
          </div>
          <div className={`col-12`}>
            <div className={`text-center ${styles.webinarLp__footer__para}`}>
              <p>©Twin Health, 2025. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;

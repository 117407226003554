import React from "react";
import { Carousel } from "react-responsive-carousel";

// components
import TestimonialCarousel from "./TestimonialCarousel";

const CarouselStories = props => {
  const cId = props.carouselId || "successStoryCarousel";
  let showIndicators = true;
  if (props.showIndicators !== undefined) {
    showIndicators = props.showIndicators;
  }

  return (
    <>
      <Carousel
        id={cId}
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        infiniteLoop={true}
        interval={2000}
        autoPlay={true}
        showIndicators={showIndicators}
        className={`webinarLandingPageCarousel`}
      >
        <TestimonialCarousel {...props.testimonialDetails[0]} />
        <TestimonialCarousel {...props.testimonialDetails[1]} />
        <TestimonialCarousel {...props.testimonialDetails[2]} />
        <TestimonialCarousel {...props.testimonialDetails[3]} />
        <TestimonialCarousel {...props.testimonialDetails[4]} />
        <TestimonialCarousel {...props.testimonialDetails[5]} />
        <TestimonialCarousel {...props.testimonialDetails[6]} />
      </Carousel>
    </>
  );
};

export default CarouselStories;

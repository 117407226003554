// extracted by mini-css-extract-plugin
export var sucessStoriesV1_button_all = "TestimonialsCarousel-module--sucessStoriesV1_button_all--5ki1C";
export var sucessStoriesV1_container = "TestimonialsCarousel-module--sucessStoriesV1_container--kZ-u8";
export var sucessStoriesV1_heading = "TestimonialsCarousel-module--sucessStoriesV1_heading--hGeva";
export var webinarLP__testimonial__arrw = "TestimonialsCarousel-module--webinarLP__testimonial__arrw--VX+IO";
export var webinarLP__testimonial__boxCont = "TestimonialsCarousel-module--webinarLP__testimonial__boxCont--QQhj2";
export var webinarLP__testimonial__boxContSec = "TestimonialsCarousel-module--webinarLP__testimonial__boxContSec--9eMaK";
export var webinarLP__testimonial__card = "TestimonialsCarousel-module--webinarLP__testimonial__card--SwMF9";
export var webinarLP__testimonial__contCol = "TestimonialsCarousel-module--webinarLP__testimonial__contCol--Mina2";
export var webinarLP__testimonial__contCol__box = "TestimonialsCarousel-module--webinarLP__testimonial__contCol__box--6cx8o";
export var webinarLP__testimonial__contCol__boxCont = "TestimonialsCarousel-module--webinarLP__testimonial__contCol__boxCont--grLAk";
export var webinarLP__testimonial__contCol__content = "TestimonialsCarousel-module--webinarLP__testimonial__contCol__content--qgArd";
export var webinarLP__testimonial__img = "TestimonialsCarousel-module--webinarLP__testimonial__img--ZnCpT";
export var webinarLP__testimonial__middleImgCol = "TestimonialsCarousel-module--webinarLP__testimonial__middleImgCol--6qWXm";
export var webinarLP__testimonial__middleImgCol__imgDiv = "TestimonialsCarousel-module--webinarLP__testimonial__middleImgCol__imgDiv--Ti8jP";
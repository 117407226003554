import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// utils
import { getSessionId } from "../../../../utils";

// Hooks
import useCountdownTimer from "../../../../hooks/useCountdownTimer";

// Component
import VideoModal from "../../VideoModal/VideoModal";

// CSS
import * as styles from "./Banner.module.css";

const WebinarBannerComponent = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [sessionId, setSessionId] = useState(getSessionId());

  const toggleVideo = () => {
    // Tracking Mixpanel Event
    props.handleMixpanelCTA("Video_Clicked", "Top Banner Video");

    // Handle Modal
    if (props.webinarVideoLink !== "") setShowVideoModal(!showVideoModal);
  };

  const updateSessionId = () => {
    setSessionId(getSessionId());
  };

  // CountDown Timer
  const { webinarTimer } = props;

  const timeLeft = useCountdownTimer(
    webinarTimer || new Date().getTime() + 3600000
  ); // Default to 1 hour countdown if webinarTimer is missing

  return (
    <>
      <div className={`container-fluid ${styles.webinarLp__banner}`}>
        <div className="row align-items-center">
          <div
            className={`col-12 col-lg-6 col-md-6 d-flex order-1 order-lg-2 ${styles.webinarLp__banner__imgDiv}`}
          >
            <div
              className={styles.webinarLp__banner__videoContainer}
              role="presentation"
              onClick={() => {
                toggleVideo();
              }}
            >
              <StaticImage
                src="../../../../images/LandingPage/webinar_banner.png"
                alt="banner_image"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 text-center text-lg-start order-2 order-lg-1">
            <h1 className={styles.webinarLp__banner__title}>
              Yes!
              <br />
              You Can Now Lead A<br />
              Diabetes Free Life
            </h1>
            <p className={styles.webinarLp__banner__subtitle}>
              Join the FREE webinar to understand
              <br />
              the science of diabetes reversal
            </p>
            <div
              className={`d-flex align-items-center justify-content-center justify-content-lg-start gap-3 my-3 ${styles.webinarLp__banner__timer}`}
            >
              <div className={styles.webinarLp__banner__timerIcn}>
                <StaticImage
                  src="../../../../images/LandingPage/timer_icn.png"
                  alt="banner_image"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div
                className={`d-flex justify-content-center justify-content-lg-start gap-1 my-3 ${styles.webinarLp__banner__timerDiv}`}
              >
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.hours).padStart(2, "0")}</span>
                  <p>Hours</p>
                </div>
                <div className="d-flex align-items-center mb-2">:</div>
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
                  <p>Minutes</p>
                </div>
                <div className="d-flex align-items-center mb-2">:</div>
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
            <AnchorLink
              to="/twin-webinar-lp/#webinarLp__registerForm"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA(
                  "Book A Free Slot",
                  "Webinar_LP Top Banner"
                )
              }
            >
              <button className={`${styles.webinarLp__banner__bookButton}`}>
                Book A Free Slot
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={props.webinarVideoLink}
        sessionId={sessionId}
        videoName={"Webinar Banner Landing Page"}
        updateSessionId={updateSessionId}
        pageName="twin-webinar-landing-page"
      />
    </>
  );
};

export default WebinarBannerComponent;

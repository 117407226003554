import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// styles
import * as styles from "./Header.module.css";

const LandingPageHeaderComponent = props => {
  return (
    <>
      <div className={`${styles.landingPageHeader}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-light ${styles.landingPageNavbar}
          `}
        >
          <div className={`container ${styles.landingPageLogo}`}>
            <div className="navbar-brand">
              <Link to={props.logoOnClickRedirect}>
                <StaticImage
                  src="../../images/twin-logo/Twin_Logo_2024.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                  className={styles.logoImage}
                />
              </Link>
            </div>
            <div className={styles.landingPageCta}>
              <AnchorLink
                to="/twin-webinar-lp/#webinarLp__registerForm"
                onAnchorLinkClick={() =>
                  props.handleMixpanelCTA("Book A Free Slot", "Header")
                }
              >
                <button>Book A Free Slot</button>
              </AnchorLink>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default LandingPageHeaderComponent;

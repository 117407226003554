import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

// CSS
import * as styles from "./ReverseManage.module.css";

const ReverseManageComponent = ({ tableHeader, tableData }) => {
  const [isMobile, setIsMobile] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth < 768);

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <div
      ref={ref}
      className={`container-fluid scroll_animate ${inView && "inview"} ${
        styles.webinarLp__reverse__cont
      }`}
    >
      <div className={`${styles.webinarLp__reverse__hdng}`}>
        <h3>We Reverse, Others Manage</h3>
        <p>
          Twin is the first in the world to offer precision treatment to reverse
          diabetes with the help of<br></br> FDA-approved sensors and AI/ML. Its
          comprehensive treatment includes precision nutrition,<br></br>{" "}
          medicine, sleep, and activity. 100% safe, simple, and natural.
        </p>
      </div>

      {isMobile ? (
        // Mobile View - Two Separate Tables
        <>
          {/* First Table: Categories */}
          <div
            className={`row ${styles.webinarLp__reverse__mobileTable__compare}`}
          >
            <div className="col-12">
              <h3 className={styles.webinarLp__reverse__mobileHeading}>
                Compare
              </h3>
              {tableData.map((item, index) => (
                <p key={index} className={styles.webinarLp__reverse__mobileRow}>
                  {item.category}
                </p>
              ))}
            </div>
          </div>

          {/* Second Table: Twin Health Reversal */}
          <div
            className={`row ${styles.webinarLp__reverse__mobileTable__reversal}`}
          >
            <div className="col-12">
              <h3 className={styles.webinarLp__reverse__mobileHeading}>
                Twin Health Reversal
              </h3>
              {tableData.map((item, index) => (
                <p key={index} className={styles.webinarLp__reverse__mobileRow}>
                  {item.twinHealth}
                </p>
              ))}
            </div>
          </div>

          {/* Third Table: Other Treatments */}
          <div
            className={`row ${styles.webinarLp__reverse__mobileTable__treatment}`}
          >
            <div className="col-12">
              <h3 className={styles.webinarLp__reverse__mobileHeading}>
                Other Treatments
              </h3>
              {tableData.map((item, index) => (
                <p key={index} className={styles.webinarLp__reverse__mobileRow}>
                  {item.otherTreatments}
                </p>
              ))}
            </div>
          </div>
        </>
      ) : (
        // Desktop View - Existing Layout
        <>
          {/* Table Headers */}
          <div className={`row ${styles.webinarLp__reverse__table}`}>
            {tableHeader.map((header, index) => (
              <div
                className={`col-md-4 ${
                  styles.webinarLp__reverse__table__hdng
                } ${
                  index === 0
                    ? styles.webinarLp__reverse__firstCol
                    : index === 1
                    ? styles.webinarLp__reverse__secondCol
                    : styles.webinarLp__reverse__thirdCol
                }`}
                key={index}
              >
                <h3>{header}</h3>
              </div>
            ))}
          </div>

          {/* Table Data */}
          {tableData.map((item, index) => (
            <div className={`row`} key={index}>
              <div
                className={`col-md-4 ${styles.webinarLp__reverse__firstTableCol}`}
              >
                <p>{item.category}</p>
              </div>
              <div
                className={`col-md-4 ${styles.webinarLp__reverse__secTableCol}`}
              >
                <p>{item.twinHealth}</p>
              </div>
              <div
                className={`col-md-4 ${styles.webinarLp__reverse__thirdTableCol}`}
              >
                <p>{item.otherTreatments}</p>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ReverseManageComponent;

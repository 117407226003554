import React from "react";
import { useInView } from "react-intersection-observer";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// components
import Carousel from "./Carousel";

//styles
import * as styles from "./TestimonialsCarousel.module.css";

const WebinarTestimonialStories = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <div className={"bgWhiteColor"}>
      <div
        ref={ref}
        className={`container scroll_animate ${inView && "inview"} ${
          styles.sucessStoriesV1_container
        } bgWhiteColor`}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.sucessStoriesV1_heading}>
              50,000+ Successful Diabetes Reversal
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <Carousel
              carouselId="WebinarTestimonialStories"
              showIndicators={true}
              testimonialDetails={props.testimonialDetails}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <AnchorLink
              to="/twin-webinar-lp/#webinarLp__registerForm"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA(
                  "Book A Free Slot",
                  "Webinar_LP Testimonial Section"
                )
              }
            >
              <button className={`btn ${styles.sucessStoriesV1_button_all}`}>
                Book A Free Slot
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarTestimonialStories;

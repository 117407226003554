import React from "react";
import { StaticImage } from "gatsby-plugin-image";

//styles
import * as styles from "./TestimonialsCarousel.module.css";

const TestimonialCarousel = props => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4">
            <div className={`${styles.webinarLP__testimonial__img}`}>
              {props.userImage}
            </div>
          </div>
          <div
            className={`col-12 col-md-2 col-lg-2 ${styles.webinarLP__testimonial__middleImgCol}`}
          >
            <div
              className={styles.webinarLP__testimonial__middleImgCol__imgDiv}
            >
              <StaticImage
                src="../../../../images/LandingPage/testimonial_component.png"
                alt="banner_image"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
          </div>
          <div
            className={`col-12 col-md-6 col-lg-6 ${styles.webinarLP__testimonial__contCol}`}
          >
            <div
              className={`${styles.webinarLP__testimonial__contCol__content}`}
            >
              <h3>{props.heading}</h3>
              <h2>{props.subHeading}</h2>
              <p>{props.description}</p>
            </div>
            <div className={`${styles.webinarLP__testimonial__card}`}>
              <div className={`${styles.webinarLP__testimonial__contCol__box}`}>
                <h3>{props.diseaseCure}</h3>
                <div
                  className={`${styles.webinarLP__testimonial__contCol__boxCont}`}
                >
                  <div className={styles.webinarLP__testimonial__boxCont}>
                    <h4>{props.programStart}</h4>
                    <p>{props.programStartText}</p>
                  </div>
                  <div className={styles.webinarLP__testimonial__arrw}>
                    <span
                      className={`icon-arrow-forward`}
                      aria-hidden="true"
                    ></span>
                  </div>
                  <div
                    className={`ps-3 ${styles.webinarLP__testimonial__boxContSec}`}
                  >
                    <h4>{props.yearEndValue}</h4>
                    <p>{props.yearText}</p>
                  </div>
                </div>
              </div>
              {/* SHOW 2ND CARD --> Only If Data Presents */}
              {props.diseaseCure_2 ? (
                <div
                  className={`${styles.webinarLP__testimonial__contCol__box}`}
                >
                  <h3>{props.diseaseCure_2}</h3>
                  <div
                    className={`${styles.webinarLP__testimonial__contCol__boxCont}`}
                  >
                    <div className={styles.webinarLP__testimonial__boxCont}>
                      <h4>{props.programStart_2}</h4>
                      <p>{props.programStartText_2}</p>
                    </div>
                    <div className={styles.webinarLP__testimonial__arrw}>
                      <span
                        className={`icon-arrow-forward`}
                        aria-hidden="true"
                      ></span>
                    </div>
                    <div
                      className={`ps-3 ${styles.webinarLP__testimonial__boxContSec}`}
                    >
                      <h4>{props.yearEndValue_2}</h4>
                      <p>{props.yearText_2}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCarousel;

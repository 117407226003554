// extracted by mini-css-extract-plugin
export var webinarLp__reverse__cont = "ReverseManage-module--webinarLp__reverse__cont--tORD6";
export var webinarLp__reverse__firstCol = "ReverseManage-module--webinarLp__reverse__firstCol--DlCX9";
export var webinarLp__reverse__firstTableCol = "ReverseManage-module--webinarLp__reverse__firstTableCol--FZs1M";
export var webinarLp__reverse__hdng = "ReverseManage-module--webinarLp__reverse__hdng--cDdaj";
export var webinarLp__reverse__mobileHeading = "ReverseManage-module--webinarLp__reverse__mobileHeading--wR8AN";
export var webinarLp__reverse__mobileRow = "ReverseManage-module--webinarLp__reverse__mobileRow--vwiZ8";
export var webinarLp__reverse__mobileTable__compare = "ReverseManage-module--webinarLp__reverse__mobileTable__compare--rYrBQ";
export var webinarLp__reverse__mobileTable__reversal = "ReverseManage-module--webinarLp__reverse__mobileTable__reversal--r0RkW";
export var webinarLp__reverse__mobileTable__treatment = "ReverseManage-module--webinarLp__reverse__mobileTable__treatment--heCbP";
export var webinarLp__reverse__secTableCol = "ReverseManage-module--webinarLp__reverse__secTableCol--LFN2J";
export var webinarLp__reverse__secondCol = "ReverseManage-module--webinarLp__reverse__secondCol--sfuas";
export var webinarLp__reverse__table = "ReverseManage-module--webinarLp__reverse__table--36uqi";
export var webinarLp__reverse__table__hdng = "ReverseManage-module--webinarLp__reverse__table__hdng--Fewkh";
export var webinarLp__reverse__thirdCol = "ReverseManage-module--webinarLp__reverse__thirdCol--K8l4u";
export var webinarLp__reverse__thirdTableCol = "ReverseManage-module--webinarLp__reverse__thirdTableCol--eiv1o";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

// services
import { Mixpanel, MixpanelEvents } from "../../../../services/Mixpanel";

// Constants
import constants from "../../../../constants/LandingPage";

// Utils
import {
  generateWebinarLandingPageSlots,
  filterWebinarLandingPageSlots,
  formatWebinarLandingPageSlotLabel,
} from "../../../../utils";

// css
import * as styles from "./RegisterForm.module.css";

const RegisterFormComponent = props => {
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [showTimeError, setShowTimeError] = useState("none");
  const [webinarSlots, setWebinarSlots] = useState([]);

  const [formTouched, setFormTouched] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm({ mode: "all" });

  // Animation On Scroll
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  if (isDirty && formTouched) {
    Mixpanel.track(MixpanelEvents.contact_form_started, {
      page_name: "twin-webinar-landing-page",
      form_is_modal: false,
    });
    setFormTouched(false);
  }

  // Function to handle dynamic webinar slots
  useEffect(() => {
    const updateWebinarSlots = () => {
      const generatedSlots = generateWebinarLandingPageSlots(
        constants.WEBINAR_TIMINGS
      );
      const filteredSlots = filterWebinarLandingPageSlots(generatedSlots);
      setWebinarSlots(filteredSlots);
      setTimeSlotValue(filteredSlots.length > 0 ? filteredSlots[0].id : "");
    };

    updateWebinarSlots();

    // Refresh slots at midnight to maintain the rolling window
    const now = new Date();
    const timeToMidnight =
      new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) - now;
    const midnightTimeout = setTimeout(() => {
      updateWebinarSlots();
      setInterval(updateWebinarSlots, 24 * 60 * 60 * 1000); // Update every 24 hours
    }, timeToMidnight);

    return () => clearTimeout(midnightTimeout);
  }, []);

  // Function to handle webinar slot selections
  const handleTimeSlotChange = e => {
    if (e.target.value) {
      setTimeSlotValue(e.target.value);
      setShowTimeError("none");
    } else {
      setShowTimeError("block");
    }
  };

  // Function to handle the form submission
  const onSubmit = data => {
    if (!timeSlotValue) {
      return setShowTimeError("block");
    }

    // Mixapnel Events
    Mixpanel.track(MixpanelEvents.contact_form_completed, {
      page_name: "twin-webinar-landing-page",
      form_is_modal: false,
      user_name: data.name,
      user_phone: data.phone,
      selected_webinar_slot: timeSlotValue.toString(),
    });

    // Leadsquared Events
    const formData = {
      name: data.name,
      phone: data.phone,
      timeSlot: timeSlotValue.toString(),
    };
    props.formDataToLsq(formData);

    setTimeSlotValue("");
    setShowTimeError("none");
    reset();
  };

  return (
    <>
      <div
        ref={ref}
        className={`container-fluid scroll_animate ${inView && "inview"} ${
          styles.webinarLp__form__cont
        }`}
      >
        <div className="row">
          <div
            className={`col-12 col-md-6 col-lg-6 order-1 order-md-2 order-lg-2`}
          >
            <div className={`${styles.webinarLp__form__bannerImg}`}>
              <StaticImage
                src="../../../../images/LandingPage/formImg.png"
                alt="banner_image"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
          </div>
          <div
            className={`col-12 col-md-6 col-lg-6 text-center order-2 order-md-1 order-lg-1 ${styles.webinarLp__form__hdngCol}`}
          >
            <div className={`${styles.webinarLp__form__border}`}></div>
            <div className={`${styles.webinarLp__form__hdng}`}>
              <h2>
                Fill out the form below,<br></br> to register now for the
                webinar
              </h2>
            </div>
            <div className={`${styles.webinarLp__form__formDiv}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3 mb-3">
                  <div
                    className={`col-md-12 ${styles.webinarLp__form__inpBox}`}
                  >
                    <input
                      type="text"
                      placeholder="Name*"
                      id="name"
                      className={
                        errors.name ? "form-control is-invalid" : "form-control"
                      }
                      {...register("name", {
                        required: "Please enter your name",
                        pattern: {
                          value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid name",
                        },
                        maxLength: {
                          value: 50,
                          message: "Name is too long",
                        },
                      })}
                    />
                    {errors.name && (
                      <span className="invalid-feedback">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div
                    className={`col-md-12 ${styles.webinarLp__form__inpBox}`}
                  >
                    <input
                      type="text"
                      placeholder="WhatsApp Number*"
                      id="phoneNumber"
                      inputMode="numeric"
                      maxLength="10"
                      className={`form-control letterSp1 ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      {...register("phone", {
                        required: "Please enter your mobile number",
                        pattern: {
                          value:
                            /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid mobile number",
                        },
                        maxLength: {
                          value: 10,
                          message: "Invalid mobile number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className="invalid-feedback">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                  <div
                    className={`col-md-12 ${styles.webinarLp__form__inpBox}`}
                  >
                    <div>
                      <select
                        className="form-select"
                        id="diagnosis"
                        value={timeSlotValue}
                        onChange={handleTimeSlotChange}
                      >
                        <option value="">Preferred webinar time slot*</option>
                        {webinarSlots.map(slot => (
                          <option
                            key={slot.id}
                            value={formatWebinarLandingPageSlotLabel(slot)}
                          >
                            {formatWebinarLandingPageSlotLabel(slot)}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* To show preferred time error */}
                    {!timeSlotValue && (
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "0.875em",
                          display: showTimeError,
                        }}
                      >
                        <p style={{ marginTop: "4px" }}>
                          Please select a preferred webinar time slot
                        </p>
                      </span>
                    )}
                  </div>
                  <div
                    className={`col-md-12 col-lg-12 ${styles.webinarLp__form__cta}`}
                  >
                    <span className="errorclass text-center text-danger">
                      {!isValid && isSubmitted}
                    </span>
                    <button
                      type="submit"
                      onClick={() => {
                        setShowTimeError("block");
                      }}
                    >
                      <span
                        className={`${
                          props.loading && styles.webinarLp__form__ctaLoader
                        }`}
                      >
                        Book A Free Slot
                      </span>
                      <span className={styles.webinarLp__form__loader}>
                        <Loader
                          type="TailSpin"
                          color="#FFF"
                          height={30}
                          width={30}
                          radius={1}
                          visible={props.loading}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterFormComponent;

import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// services
import { sendToLSQ } from "../services/LeadSquared";
import { Mixpanel, MixpanelEvents } from "../services/Mixpanel";

// constants
import constants from "../constants/LandingPage";

// utils
import { saveUTM } from "../utils";

// components
import LandingPageHeaderComponent from "../components/Header/LandingPageHeader";
import WebinarBannerComponent from "../components/LandingPages/Webinar/Banner/Banner";
import WebinarLearn from "../components/LandingPages/Webinar/WebinarLearn/WebinarLearn";
import DoctorsSection from "../components/LandingPages/Webinar/Doctors/Doctors";
import WebinarTestimonialStories from "../components/LandingPages/Webinar/TestimonialCarousel/WebinarTestimonialStories";
import ReverseManageComponent from "../components/LandingPages/Webinar/ReverseManage/ReverseManage";
import AboutTwinComponent from "../components/LandingPages/Webinar/AboutTwin/AboutTwin";
import BodyMetabolismComponent from "../components/LandingPages/Webinar/BodyMetabolism/BodyMetabolism";
import RegisterFormComponent from "../components/LandingPages/Webinar/RegisterForm/RegisterForm";
import FooterComponent from "../components/LandingPages/Webinar/Footer/Footer";

const TwinWebinarLandingPage = props => {
  const [depth25, setDepth25] = useState(true);
  const [depth50, setDepth50] = useState(true);
  const [depth75, setDepth75] = useState(true);
  const [depth100, setDepth100] = useState(true);
  const [loading, setLoading] = useState(false);
  const [operatingSystem, setOperatingSystem] = useState(""); // To store operating system
  const [deviceType, setDeviceType] = useState(""); // To store the device type

  const webinarTimer = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now

  // Save utm to local storage
  useEffect(() => {
    if (props.location.search) {
      saveUTM(props.location.search);
    }
  }, [props.location.search]);

  useEffect(() => {
    let loadTime =
      window.performance.timing.domContentLoadedEventEnd -
        window.performance.timing.navigationStart || 700;

    let utmParams = queryString.parse(props.location.search);

    // Mixpanel Event
    Mixpanel.track(MixpanelEvents.page_viewed, {
      page_name: "twin-webinar-landing-page",
      load_time: loadTime,
      campaign_id: utmParams.campaignid,
      device_type: deviceType,
    });
  }, [props.location.search, deviceType]);

  useEffect(() => {
    const handleScroll = () => {
      const pageYOffset = window.pageYOffset;
      const height = document.body.scrollHeight - window.innerHeight;
      const progress = Math.round((pageYOffset / height) * 100);

      if (progress === 25 && depth25) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-webinar-landing-page",
          page_depth: progress,
        });
        setDepth25(false);
      } else if (progress === 50 && depth50) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-webinar-landing-page",
          page_depth: progress,
        });
        setDepth50(false);
      } else if (progress === 75 && depth75) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-webinar-landing-page",
          page_depth: progress,
        });
        setDepth75(false);
      } else if (progress === 100 && depth100) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-webinar-landing-page",
          page_depth: progress,
        });
        setDepth100(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [depth25, depth50, depth75, depth100]);

  // Find The Operating System Of The User
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let operatingSystem = "";

    if (userAgent.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    } else if (userAgent.indexOf("Mac") !== -1) {
      operatingSystem = "Mac OS";
    } else if (userAgent.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    } else if (userAgent.indexOf("Android") !== -1) {
      operatingSystem = "Android OS";
    } else if (userAgent.indexOf("iOS") !== -1) {
      operatingSystem = "iOS";
    } else {
      operatingSystem = "Unknown";
    }

    setOperatingSystem(operatingSystem);
  }, []);

  // Find The Device Type Of The User
  useEffect(() => {
    // Function to update device type based on screen width
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceType("Mobile");
      } else if (width <= 1024) {
        setDeviceType("Tablet");
      } else {
        setDeviceType("Desktop");
      }
    };

    // Update device type when the component mounts
    updateDeviceType();

    // Add an event listener to update device type when the window is resized
    window.addEventListener("resize", updateDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  // To Track Mixpanel Event When User Leave Page
  useEffect(() => {
    const handlePageExit = event => {
      Mixpanel.track(MixpanelEvents.page_exit, {
        page_name: "twin-webinar-landing-page",
      });
    };

    window.addEventListener("beforeunload", handlePageExit);

    return () => {
      window.removeEventListener("beforeunload", handlePageExit);
    };
  }, []);

  // Function --> Form Data send to LSQ
  const formDataToLsq = async values => {
    try {
      setLoading(true);
      // leadSquared params
      let leadSquaredParams = {
        FirstName: values.name,
        Phone: values.phone,
        mx_Preferred_time_of_call: values.timeSlot,
        mx_Website_CTA: "twin_webinar_landing_page",
        mx_Latest_Source: "New Website",
        mx_Device_Mobile_Desktop: deviceType,
        mx_msc_Operating_System: operatingSystem,
      };

      await sendToLSQ(leadSquaredParams);
      setLoading(false);
      // redirection to thank you page
      navigate("/twin-landing-ty");
    } catch (error) {
      console.log("Error Leadsquared Form Webinar LP - formDataToLsq:", error);
    }
  };

  const handleMixpanelCTA = (ctaText, ctaLocation) => {
    Mixpanel.track(MixpanelEvents.contact_button_clicked, {
      page_name: "twin-webinar-landing-page",
      cta_text: ctaText,
      cta_location: ctaLocation,
    });
  };

  return (
    <div>
      <Seo
        title="Achieve Diabetes Remission, reclaim your health."
        description="Achieve Diabetes Remission. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      <LandingPageHeaderComponent handleMixpanelCTA={handleMixpanelCTA} />
      <WebinarBannerComponent
        webinarVideoLink={constants.WEBINAR_BANNER_VIDEO}
        handleMixpanelCTA={handleMixpanelCTA}
        webinarTimer={webinarTimer}
      />
      <WebinarLearn webinarLearnData={constants.WEBINAR_LEARNING_POINTS} />
      <DoctorsSection
        doctorDetails={constants.WEBINAR_DOCTORSDETAILS}
        handleMixpanelCTA={handleMixpanelCTA}
      />
      <WebinarTestimonialStories
        handleMixpanelCTA={handleMixpanelCTA}
        testimonialDetails={constants.WEBINAR_TESTIMONIAL_DETAILS}
      />
      <ReverseManageComponent
        tableHeader={constants.WEBINAR_REVERSEMANAGE_HEADER}
        tableData={constants.WEBINAR_REVERSEMANAGE}
      />
      <AboutTwinComponent />
      <BodyMetabolismComponent
        handleMixpanelCTA={handleMixpanelCTA}
        webinarTimer={webinarTimer}
      />
      <div id="webinarLp__registerForm">
        <RegisterFormComponent
          loading={loading}
          formDataToLsq={formDataToLsq}
        />
      </div>
      <FooterComponent />
    </div>
  );
};

export default TwinWebinarLandingPage;

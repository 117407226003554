import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import * as styles from "./AboutTwin.module.css";

const AboutTwinComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div
        ref={ref}
        className={`container-fluid scroll_animate ${inView && "inview"} ${
          styles.webinarLp__aboutTwin__cont
        }`}
      >
        <div className={`row ${styles.webinarLp__aboutTwin__row}`}>
          <div
            className={`col-12 col-md-3 col-lg-3 ${styles.webinarLp__aboutTwin__col}`}
          >
            <div className={styles.webinarLp__aboutTwin__img}>
              <StaticImage
                src="../../../../images/LandingPage/jahangir-2.png"
                alt="banner_image"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
          </div>
          <div
            className={`col-12 col-md-9 col-lg-9  ${styles.webinarLp__aboutTwin__col}`}
          >
            <div className={styles.webinarLp__aboutTwin__content}>
              <h3>About Twin Health</h3>
              <p>
                Founded by Dr Jahangir Mohammed, Twin Health, Inc. pioneered
                Whole Body Digital Twin™, a precision health platform, to help
                members safely achieve diabetes remission of type 2 diabetes and
                other chronic diseases.
              </p>
              <p>
                With artificial intelligence and Internet of things
                technologies, the Twin Health Platform offers a highly technical
                yet highly personalized experience for members as physicians and
                coaches guide them to repair the root cause of disease. Twin
                Health is present in India and the US and comprises a team of
                500+ passionate people across nations and is published in over
                20 peer-reviewed journals and research papers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTwinComponent;

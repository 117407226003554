import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useInView } from "react-intersection-observer";

// CSS
import * as styles from "./Doctors.module.css";

const DoctorsComponent = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div
        ref={ref}
        className={`container-fluid scroll_animate ${inView && "inview"} ${
          styles.webinarLp__doctor
        }`}
      >
        <div className={`${styles.webinarLp__doctor__hdng}`}>
          <h2 className="h2">Get All Your Queries Answered</h2>
          <h2>Live With Our Expert Doctor</h2>
        </div>
        <div className={`row ${styles.webinarLp__doctor__row}`}>
          {props.doctorDetails.map((point, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className={`${styles.webinarLp__doctor__card}`}>
                <div className={`row`}>
                  <div
                    className={`col-12 col-md-2 col-lg-2 ${styles.webinarLp__doctor__img}`}
                  >
                    {point.img}
                  </div>
                  <div
                    className={`col-12 col-md-10 col-lg-10 ${styles.webinarLp__doctor__cardDetails}`}
                  >
                    <h3>{point.name}</h3>
                    <p>{point.designation}</p>
                    <p>{point.experience}</p>
                    <p>{point.patients}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`text-center ${styles.webinarLp__doctor__cta}`}>
          <AnchorLink
            to="/twin-webinar-lp/#webinarLp__registerForm"
            onAnchorLinkClick={() =>
              props.handleMixpanelCTA(
                "Book A Free Slot",
                "Webinar_LP Doctor Section"
              )
            }
          >
            <button>Book A Free Slot</button>
          </AnchorLink>
        </div>
      </div>
    </>
  );
};

export default DoctorsComponent;

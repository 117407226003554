import React from "react";
import { useInView } from "react-intersection-observer";

// CSS
import * as styles from "./WebinarLearn.module.css";

const WebinarLearnComponent = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div
        ref={ref}
        className={`container-fluid scroll_animate ${inView && "inview"} ${
          styles.webinarLp__learn
        }`}
      >
        <div className={`${styles.webinarLp__learn__hdng}`}>
          <h2 className="h2">In this webinar, you will learn</h2>
        </div>
        <div className={`row ${styles.webinarLp__learn__row}`}>
          <div className="col-md-1 col-lg-1"></div>
          <div className="col-12 col-md-10 col-lg-10">
            <div className="row">
              {props.webinarLearnData.map((point, index) => (
                <div
                  key={index}
                  className={`col-6 col-md-4 col-lg-4 mb-4 ${styles.webinarLp__learn__col}`}
                >
                  <div className={`${styles.webinarLp__learn__card}`}>
                    <span className="icon">{point.icon}</span>
                    <p>{point.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-1 col-lg-1"></div>
        </div>
      </div>
    </>
  );
};

export default WebinarLearnComponent;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useInView } from "react-intersection-observer";

// Hooks
import useCountdownTimer from "../../../../hooks/useCountdownTimer";

// CSS
import * as styles from "./BodyMetabolism.module.css";

const BodyMetabolismComponent = props => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { webinarTimer } = props;

  const timeLeft = useCountdownTimer(
    webinarTimer || new Date().getTime() + 3600000
  ); // Default to 1 hour countdown if webinarTimer is missing

  return (
    <>
      <div
        ref={ref}
        className={`container-fluid scroll_animate ${inView && "inview"} ${
          styles.webinarLp__bodyMeta__cont
        }`}
      >
        <div className={`row`}>
          <div
            className={`col-12 text-center ${styles.webinarLp__bodyMeta__col}`}
          >
            <h3>
              We Understand Your Body<br></br> Metabolism Better Than Anyone
            </h3>
            <p>
              Damaged metabolism is the root cause of diabetes and can severely
              hamper your regular life.<br></br> Twin Health’s webinar will help
              you understand your body’s metabolism and guide you on steps
              <br></br> to take for a diabetes-free life!
            </p>
            <div
              className={`d-flex align-items-center justify-content-center justify-content-lg-start gap-3 my-3 ${styles.webinarLp__banner__timer}`}
            >
              <div className={styles.webinarLp__banner__timerIcn}>
                <StaticImage
                  src="../../../../images/LandingPage/timer_icn.png"
                  alt="banner_image"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div
                className={`d-flex justify-content-center justify-content-lg-start gap-1 my-3 ${styles.webinarLp__banner__timerDiv}`}
              >
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.hours).padStart(2, "0")}</span>
                  <p>Hours</p>
                </div>
                <div className="d-flex align-items-center mb-2">:</div>
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
                  <p>Minutes</p>
                </div>
                <div className="d-flex align-items-center mb-2">:</div>
                <div className={styles.webinarLp__banner__timerUnit}>
                  <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
            <AnchorLink
              to="/twin-webinar-lp/#webinarLp__registerForm"
              onAnchorLinkClick={() =>
                props.handleMixpanelCTA(
                  "Book A Free Slot",
                  "Webinar_LP Body Metabolism Section"
                )
              }
            >
              <button className={`${styles.webinarLp__banner__bookButton}`}>
                Book A Free Slot
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default BodyMetabolismComponent;
